import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { addTruckPopup, trucksSearchFAQ } from 'constants/pages';
import { tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { TabsContent } from 'layout/TabsContent';
import { QueryType } from 'store/QueryStore/QueryStore';
import { SavedFilter } from 'store/TabsDataStore/TabsDataStore';
import { openPopupOnCenter } from 'utils/nativeDOM';
import { interpolate } from 'utils/string/format';

import { TrucksIcon } from './TrucksIcon';

import css from './Trucks.module.css';

const Trucks = (props: { className?: string }) => {
  const {
    app: { i18n, env, fillFilterFromQuery, loading },
    query,
    tabsData,
    ui,
  } = useStore();

  useEffect(() => {
    const onTruckAdded = (event: MessageEvent) => {
      if (event.data) {
        try {
          const parsedData = JSON.parse(event.data);

          if (parsedData.eventType === 'saveFilterEvent') {
            tabsData.fetchTabsData();
          }
        } catch (error) {}
      }
    };

    window.addEventListener('message', onTruckAdded, false);

    return () => {
      window.removeEventListener('message', onTruckAdded, false);
    };
  }, [tabsData]);

  const onAddTruck = () => {
    openPopupOnCenter({ url: interpolate(addTruckPopup, { env }), h: 520, w: 600 });
  };

  const handleFilterClick = (filter: SavedFilter) => {
    ui.setActiveTab(tabIds.search);
    tabsData.selectFilter(filter);
    query.setParsedQueryData(filter.filter);
    query.setType(QueryType.byFilter);
    fillFilterFromQuery({ skipQueryParsing: true });
  };

  return (
    <TabsContent<SavedFilter>
      activeItem={tabsData.selectedFilter}
      className={props.className}
      header={
        <TabsContent.Header>
          <div className={css.linksContainer}>
            <button className={cn('glz-link', css.addTruck)} onClick={onAddTruck}>
              {i18n.trucks.addTruck}
            </button>

            <a
              className={cn('glz-link', 'glz-is-gray', css.link)}
              href={interpolate(trucksSearchFAQ, { env })}
              rel="noreferrer"
              target="_blank"
            >
              {i18n.trucks.moreOnTrucks}
            </a>
          </div>
        </TabsContent.Header>
      }
      isLoading={loading.tabsData}
      items={tabsData.data?.truckSearches || []}
      itemsCounter="truck"
      noContentElement={
        <TabsContent.NoContent
          actionElement={
            <button
              className={cn(css.button, 'glz-button', 'glz-is-primary', 'glz-is-small')}
              type="button"
              onClick={onAddTruck}
            >
              {i18n.trucks.addTruck}
            </button>
          }
          desc={i18n.trucks.noContent.desc}
          icon={<TrucksIcon />}
          title={i18n.trucks.noContent.title}
        />
      }
      onItemClick={handleFilterClick}
    />
  );
};

const connected = observer(Trucks);

export { connected as Trucks };
