import { observer } from 'mobx-react-lite';
import type { FC } from 'react';

import { Pagination } from 'components/Pagination';
import { useStore } from 'hooks/useStore';
import { followedLoadsTabIds } from 'store/UIStore/UIStore';

const FollowedLoadsPagination: FC<TFollowedLoadsPaginationProps> = ({ className }) => {
  const {
    app: { locale },
    followedLoads,
    ui: { pagination },
  } = useStore();

  const handlePageChange = ({ selected }: { selected: number }) => {
    pagination.setCurrentPage(selected);

    if (pagination.activeTab === followedLoadsTabIds.onlyFavorites) {
      followedLoads.favorites.updateLoads();
    }

    if (pagination.activeTab === followedLoadsTabIds.onlyWithOffers) {
      followedLoads.loadsWithOffers.updateLoads();
    }
  };

  if (pagination.totalPages <= 1) return null;

  return (
    <Pagination
      className={className}
      disabled={false}
      initialPage={pagination.currentPage}
      locale={locale}
      pageCount={pagination.totalPages}
      tooltip={false}
      onPageChange={handlePageChange}
    />
  );
};

export type TFollowedLoadsPaginationProps = {
  className?: string;
};

const connected = observer(FollowedLoadsPagination);

export { connected as FollowedLoadsPagination };
