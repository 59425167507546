import { useMatchMedia } from '@ati/hooks';
import Preloader from '@components/preloader';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { columnsCount, mediaQuery } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { TSubscribedFilter } from 'store/Subscriptions/SubscribedFilter';
import type { SavedFilter } from 'store/TabsDataStore/TabsDataStore';

import { Card } from './Card';
import { Footer } from './Footer';
import { Header } from './Header';
import { NoContent } from './NoContent';

import css from './TabsContent.module.css';

interface ITabsContent<T> {
  itemsPerColumn?: number;
  header: ReactNode;
  noContentElement?: ReactNode;
  items: T[];
  onItemClick: (item: T) => void;
  onItemDelete?: (item: T) => void;
  onItemSubscribe?: (item: T) => void;
  onItemUsubscribe?: (item: T) => void;
  isLoading: boolean;
  activeItem: SavedFilter | null;
  itemsCounter?: 'filter' | 'truck';
  className?: string;
}

const TabsContent = <T extends TSubscribedFilter>({
  itemsPerColumn = 5,
  header = null,
  items = [],
  onItemClick = () => {},
  onItemDelete,
  onItemSubscribe,
  onItemUsubscribe,
  isLoading = false,
  activeItem,
  noContentElement = null,
  itemsCounter,
  className,
}: ITabsContent<T>) => {
  const {
    ui: { pagination },
  } = useStore();

  let columns: typeof columnsCount[keyof typeof columnsCount] = columnsCount.desktop;
  const filtersTablet = useMatchMedia(mediaQuery.filtersTablet);
  const filtersMobile = useMatchMedia(mediaQuery.filtersMobile);

  if (filtersTablet) columns = columnsCount.tablet;
  if (filtersMobile) columns = columnsCount.mobile;

  const pageCount = Math.ceil(items.length / (columns * itemsPerColumn));
  const pageBorder = (pagination.currentPage - 1) * columns;

  if (pagination.currentPage > 1 && pagination.currentPage > pageCount) pagination.setCurrentPage(pageCount);

  const content = [];

  for (let i = 0; i < columns; i++) {
    const filtersArr = items.slice((i + pageBorder) * itemsPerColumn, (i + 1 + pageBorder) * itemsPerColumn);

    content.push(
      <div key={`column-${i}`} className={css.column}>
        {filtersArr.map(item => {
          const isActive = item.id === activeItem?.id;

          return (
            <Card<T>
              key={item.id}
              isActive={isActive}
              item={item}
              onItemClick={onItemClick}
              onItemDelete={onItemDelete}
              onItemSubscribe={onItemSubscribe}
              onItemUsubscribe={onItemUsubscribe}
            />
          );
        })}
      </div>,
    );
  }

  return (
    <div className={cn(css.container, className)}>
      {isLoading && (
        <div className={css.preloader}>
          <Preloader width={64} />
        </div>
      )}

      <div>{header}</div>

      {items.length ? (
        <div className={css.content}>{content}</div>
      ) : (
        <div className={css.noContent}>{noContentElement}</div>
      )}

      <Footer
        activePage={pagination.currentPage}
        items={items}
        itemsCounter={itemsCounter}
        pageCount={pageCount}
        setActivePage={pagination.setCurrentPage}
      />
    </div>
  );
};

TabsContent.Header = Header;
TabsContent.NoContent = NoContent;

const connected = observer(TabsContent);

export { connected as TabsContent };
