import Select from '@components/select';
import { observer } from 'mobx-react-lite';

import { Field } from 'components/Field';
import { SortingTypes } from 'constants/sorting';
import { useStore } from 'hooks/useStore';
import { OptionType } from 'store/fileds/SelectField';

import css from './Sorting.module.css';

function Sorting() {
  const {
    app: { i18n, searchLoads },
    filter: { sortingType },
  } = useStore();

  const handleSortingTypeChange = (option: OptionType<SortingTypes>) => {
    sortingType.setOption(option);
    searchLoads();
  };

  return (
    <Field
      isRow
      stretchContent
      containerClassName={css.fieldContainer}
      contentClassName={css.fieldContent}
      contentWidth={172}
      labelClassName={css.fieldLabel}
      labelText={i18n.sorting.sortBy}
    >
      <Select
        className={css.select}
        isSearchable={false}
        options={sortingType.options}
        value={sortingType.option}
        onChange={handleSortingTypeChange}
      />
    </Field>
  );
}

const connected = observer(Sorting);

export { connected as Sorting };
