import { useMatchMedia } from '@ati/hooks';
import ButtonGroup from '@components/button-group';
import Select from '@components/select';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { TFollowedLoadsTabIds } from 'store/UIStore/UIStore';

import css from './ContentSwitcher.module.css';

const ContentSwitcher = () => {
  const {
    ui: { followedLoadsTab },
  } = useStore();

  const isMobile = useMatchMedia('(max-width: 679px)');

  return (
    <>
      {!isMobile && (
        <ButtonGroup<TFollowedLoadsTabIds>
          className={css.buttonGroup}
          options={followedLoadsTab.options}
          value={followedLoadsTab.data}
          onChange={followedLoadsTab.setOptionByValue}
        />
      )}

      {isMobile && (
        <Select
          className={css.select}
          options={followedLoadsTab.options}
          value={followedLoadsTab.option}
          onChange={followedLoadsTab.setOption}
        />
      )}
    </>
  );
};

const connected = observer(ContentSwitcher);

export { connected as ContentSwitcher };
