import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { NotificationIcon } from 'components/NotificationIcon';
import { addInvoice, virtualWalletFaq } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import css from './Title.module.css';

const Title = () => {
  const {
    app: { i18n, env },
    savedFilters: { popup },
  } = useStore();

  if (!popup) return null;

  let MainTitle = <>{i18n.filterPopup.title[popup.mode]}</>;

  if (popup?.subscribedFilter?.hasNotEnoughMoney.active) {
    MainTitle = (
      <div className={css.title}>
        <NotificationIcon className={css.icon} notification={popup.subscribedFilter.hasNotEnoughMoney} />

        <div className={css.textContainer}>
          <h2 className={cn(css.heading, 'glz-h glz-is-size-2')}>
            {i18n.subscriptions.notifications.hasNotEnoughMoney.filterPopup.heading}
          </h2>

          <span className="glz-p glz-is-size-2-secondary">
            <a
              className={cn(css.link, 'glz-link glz-is-primary glz-is-large')}
              href={interpolate(addInvoice, { env })}
              rel="noreferrer"
              target="_blank"
            >
              {i18n.subscriptions.notifications.hasNotEnoughMoney.filterPopup.text.link}
            </a>{' '}
            {i18n.subscriptions.notifications.hasNotEnoughMoney.filterPopup.text.desc}
          </span>
        </div>
      </div>
    );
  }

  if (popup?.subscribedFilter?.isVirtuallyBlocked.active) {
    MainTitle = (
      <div className={css.title}>
        <NotificationIcon className={css.icon} notification={popup.subscribedFilter.isVirtuallyBlocked} />

        <div className={css.textContainer}>
          <h2 className={cn(css.heading, 'glz-h glz-is-size-2')}>
            {i18n.subscriptions.notifications.isVirtuallyBlocked.filterPopup.heading}
          </h2>
          <span className={css.text}>
            {i18n.subscriptions.notifications.isVirtuallyBlocked.filterPopup.text.desc}{' '}
            <a
              className={cn(css.link, 'glz-link glz-is-big glz-is-primary glz-is-underline')}
              href={interpolate(virtualWalletFaq, { env })}
              rel="noreferrer"
              target="_blank"
            >
              {i18n.subscriptions.notifications.isVirtuallyBlocked.filterPopup.text.link}
            </a>
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      {MainTitle}

      {popup?.subscribedFilter?.isSMSStopped.active && (
        <div className={css.sms}>
          <NotificationIcon notification={popup.subscribedFilter.isSMSStopped} />{' '}
          <span>{popup.subscribedFilter.isSMSStopped.description}</span>
        </div>
      )}
    </>
  );
};

const connected = observer(Title);

export { connected as Title };
