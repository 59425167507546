import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { ActionPopover } from 'components/ActionPopover';
import { favoritesFAQ } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { interpolate } from 'utils/string/format';

import { Content } from './Content';
import { ContentSwitcher } from './ContentSwitcher';

import css from './FollowedLoads.module.css';

const FollowedLoads = () => {
  const {
    siteFeatures,
    app: { i18n, env },
    followedLoads: { favorites },
  } = useStore();

  return (
    <div className={css.container}>
      <div className={css.header}>
        {siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.newFavoritesTab] && <ContentSwitcher />}

        <div
          className={cn(css.links, {
            [css.links_newTab]: siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.newFavoritesTab],
          })}
        >
          {!siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.newFavoritesTab] && (
            <>
              <ActionPopover
                alternateAction={i18n.actionPopover.delete.atlernate}
                primaryAction={i18n.actionPopover.delete.primary}
                title={i18n.favorites.deleteUnactual.popover}
                onConfirm={favorites.removeUnactualFavorites}
              >
                <button className={cn('glz-link', css.link)}>{i18n.favorites.deleteUnactual.button}</button>
              </ActionPopover>

              <ActionPopover
                alternateAction={i18n.actionPopover.delete.atlernate}
                primaryAction={i18n.actionPopover.delete.primary}
                title={i18n.favorites.deleteAll.popover}
                onConfirm={favorites.removeAllFavorites}
              >
                <button className={cn('glz-link', css.link)}>{i18n.favorites.deleteAll.button}</button>
              </ActionPopover>
            </>
          )}

          <a
            className={cn('glz-link glz-is-gray', css.more)}
            href={interpolate(favoritesFAQ, { env })}
            rel="noreferrer"
            target="_blank"
          >
            {i18n.favorites.more}
          </a>
        </div>
      </div>
    </div>
  );
};

FollowedLoads.Content = Content;

const connected = observer(FollowedLoads);

export { connected as FollowedLoads };
