import { observer } from 'mobx-react-lite';

import { Column } from 'components/Column';
import { OptionsList } from 'components/OptionsList';
import { useStore } from 'hooks/useStore';

import { Mobile } from './Mobile';
interface ExtraParamsProps {
  className?: string;
}

const ExtraParams = (props: ExtraParamsProps) => {
  const {
    app: { i18n },
    ui: { isAdvancedFiltersVisible, toggleIsAdvancedFiltersVisible },
    filter: { extraParams, isExtraParamsDefault },
    options,
  } = useStore();

  return (
    <Column
      className={props.className}
      header={i18n.extraParams.label}
      isContentVisible={isAdvancedFiltersVisible}
      isHighlighted={!isExtraParamsDefault}
      onClick={toggleIsAdvancedFiltersVisible}
    >
      <OptionsList
        options={options.getExtraParams()}
        selectedValues={extraParams.params}
        valueProperty="id"
        onChange={extraParams.setParam}
      />
    </Column>
  );
};

ExtraParams.Mobile = Mobile;

const connected = observer(ExtraParams);

export { connected as ExtraParams };
