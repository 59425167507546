import Select from '@components/select';
import { observer } from 'mobx-react-lite';

import { Field } from 'components/Field';
import { useStore } from 'hooks/useStore';

function Time() {
  const {
    app: { i18n },
    filter: { changeDate },
  } = useStore();

  return (
    <Field isRow stretchContent contentWidth={120} labelHighlighted={!changeDate.isDefault} labelText={i18n.time.added}>
      <Select
        isSearchable={false}
        options={changeDate.options}
        value={changeDate.option}
        onChange={changeDate.setOption}
      />
    </Field>
  );
}

const connected = observer(Time);

export { connected as Time };
