import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { stikyHeaderHeight } from 'constants/ui';
import { useIntersection } from 'hooks/useIntersection';
import { useStore } from 'hooks/useStore';
import { TTabIds } from 'store/UIStore/UIStore';

import { Static } from './Static/Static';
import { Sticky } from './Sticky';
import { TabType } from './Tab';

import css from './Tabs.module.css';

export interface TabsProps {
  tabsData: TabType[];
  isPinned: boolean;
  onPin: () => void;
  activeTab: string;
  onChange: (tab: TTabIds) => void;
  onDisabledClick: () => void;
}

const Tabs = (props: TabsProps) => {
  const { app, ui } = useStore();

  const { isPinned } = props;
  const [ref, observableEntry] = useIntersection({ rootMargin: `-${stikyHeaderHeight}`, threshold: 0.5 });
  const isSticky = !app.loading.allResources && Boolean(observableEntry) && !observableEntry?.isIntersecting;

  useEffect(() => {
    ui.setIsSticky(isSticky);
  }, [isSticky, ui]);

  return (
    <div ref={ref} className={css.refContainer}>
      {isSticky && !isPinned && <Sticky {...props} />}
      <Static {...props} isSticky={isSticky} />
    </div>
  );
};

const connected = observer(Tabs);

export { connected as Tabs };
