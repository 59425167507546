import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { followedLoadsTabIds } from 'store/UIStore/UIStore';

import { ContentWithFavorites } from './ContentWithFavorites';
import { ContentWithOffers } from './ContentWithOffers';

const Content = () => {
  const {
    ui: { followedLoadsTab },
  } = useStore();

  return (
    <>
      {followedLoadsTab.data === followedLoadsTabIds.onlyFavorites && <ContentWithFavorites />}

      {followedLoadsTab.data === followedLoadsTabIds.onlyWithOffers && <ContentWithOffers />}
    </>
  );
};

const connected = observer(Content);

export { connected as Content };
