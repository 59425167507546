import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import { SubscriptionMessage } from '../SubscriptionMessage';

const FixedPriceMessage = () => {
  const {
    app: { i18n },
    savedFilters: { popup },
    profile,
    view,
  } = useStore();

  const hasFixedPrice = popup?.validationResult?.resultFilter.subscription?.rate;
  const shouldShowMessage = hasFixedPrice && !profile.hasUnlimitedNotificationsLicense;

  if (shouldShowMessage) {
    return (
      <SubscriptionMessage>
        {i18n.subscriptions.fixedRate.firstPart}{' '}
        <strong>
          {view.atisCount(popup.validationResult?.resultFilter.subscription.rate)}{' '}
          {i18n.subscriptions.fixedRate.secondPart}
        </strong>
        <br />
        {i18n.subscriptions.fixedRate.couldBeChanged}
      </SubscriptionMessage>
    );
  }

  return null;
};

const connected = observer(FixedPriceMessage);

export { connected as FixedPriceMessage };
