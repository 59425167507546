/* eslint-disable import/order */
import type { SnakeCasedPropertiesDeep } from 'type-fest';

import type { FilterType } from 'store/FilterStore/types';

type Modify<T, R> = Omit<T, keyof R> & R;

import { SearchRequestType } from 'api/search/types';

export type PrettyLoadSearchRequestType = Modify<
  SnakeCasedPropertiesDeep<SearchRequestType>,
  {
    filter?: Modify<SnakeCasedPropertiesDeep<FilterType>, { loading_type?: number | string | null }>;
  }
>;

export const tweakDescriptorForTendersCard = (
  descriptor: SnakeCasedPropertiesDeep<SearchRequestType>,
): PrettyLoadSearchRequestType => {
  const { filter } = descriptor;

  const { dogruz, loading_type } = filter;

  return {
    ...descriptor,
    filter: {
      ...filter,
      dogruz: dogruz || null,
      loading_type: loading_type === '0' ? 0 : loading_type,
    },
  };
};
