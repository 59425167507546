import Select from '@components/select';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Field } from 'components/Field';
import { RestrictedInput } from 'components/RestrictedInput';
import { inputRestriction } from 'constants/inputRestrictions';
import { useStore } from 'hooks/useStore';

import css from './DistanceRange.module.css';

interface IDistanceRangeProps {
  className?: string;
  mode?: 'ellipse' | 'length' | 'full';
}

const DistanceRange = ({ className, mode = 'full' }: IDistanceRangeProps) => {
  const {
    app: { i18n },
    filter: { routeParams },
  } = useStore();

  const shouldShowEllipse = routeParams.isEllipseActive && (mode === 'ellipse' || mode === 'full');
  const shoudShowLRouteLength = routeParams.isRouteLengthActive && (mode === 'length' || mode === 'full');

  return (
    <div className={className}>
      {shouldShowEllipse && (
        <div className={css.container}>
          <div className={css.label}>
            <p className={cn(css.desc, 'glz-p glz-is-size-3')}>{i18n.routeParams.detourDistance}</p>
          </div>

          <Field
            containerClassName={cn(css.distanceInputContainer, css.ellipseInputContainer)}
            labelClassName={css.inputLabel}
            labelContent={<Field.TitledLabel {...i18n.routeParams.maxRouteLength} />}
          >
            <RestrictedInput
              clearZeroOnBlur
              className={css.ellipseInput}
              customRestrict={['leadingZero', 'floatNumbers', 'leadingSymbols']}
              inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
              maxLength={5}
              value={routeParams.maxEnlargment.view}
              onValueChange={routeParams.maxEnlargment.setValueFromInput}
            />
            <Select
              className={css.select}
              options={routeParams.maxEnlargmentUnit.options}
              value={routeParams.maxEnlargmentUnit.option}
              onChange={routeParams.maxEnlargmentUnit.setOption}
            />
          </Field>

          <Field
            containerClassName={cn(css.distanceInputContainer, css.ellipseInputContainer)}
            labelClassName={css.inputLabel}
            labelContent={<Field.TitledLabel {...i18n.routeParams.minTranspDistance} />}
          >
            <RestrictedInput
              clearZeroOnBlur
              className={css.ellipseInput}
              customRestrict={['leadingZero', 'floatNumbers', 'leadingSymbols']}
              inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
              maxLength={5}
              value={routeParams.minLength.view}
              onValueChange={routeParams.minLength.setValueFromInput}
            />
            <Select
              className={css.select}
              options={routeParams.minLengthUnit.options}
              value={routeParams.minLengthUnit.option}
              onChange={routeParams.minLengthUnit.setOption}
            />
          </Field>
        </div>
      )}

      {shoudShowLRouteLength && (
        <div className={css.container}>
          <div className={css.label}>
            <p className={cn(css.desc, 'glz-p glz-is-size-3')}>{i18n.routeParams.routeLengthRange}</p>
          </div>

          <Field
            containerClassName={cn(css.distanceInputContainer, css.lengthInputContainer)}
            contentClassName={css.fieldContent}
            labelClassName={css.inputLabel}
            labelContent={<Field.TitledLabel {...i18n.routeParams.minDistance} />}
          >
            <RestrictedInput
              clearZeroOnBlur
              after={i18n.common.km}
              className={css.routeLength}
              customRestrict={['leadingZero', 'floatNumbers', 'leadingSymbols']}
              inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
              maxLength={5}
              placeholder={i18n.common.from}
              value={routeParams.routeLength.min.view}
              onInputBlur={routeParams.routeLength.handleSwap}
              onValueChange={routeParams.routeLength.min.setValueFromInput}
            />
          </Field>

          <Field
            containerClassName={cn(css.distanceInputContainer, css.lengthInputContainer)}
            contentClassName={css.fieldContent}
            labelClassName={css.inputLabel}
            labelContent={<Field.TitledLabel {...i18n.routeParams.maxDistance} />}
          >
            <RestrictedInput
              clearZeroOnBlur
              after={i18n.common.km}
              className={css.routeLength}
              customRestrict={['leadingZero', 'floatNumbers', 'leadingSymbols']}
              inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
              maxLength={5}
              placeholder={i18n.common.to}
              value={routeParams.routeLength.max.view}
              onInputBlur={routeParams.routeLength.handleSwap}
              onValueChange={routeParams.routeLength.max.setValueFromInput}
            />
          </Field>
        </div>
      )}
    </div>
  );
};

const connected = observer(DistanceRange);

export { connected as DistanceRange };
