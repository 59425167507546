import Icon from '@components/icon';
import IconWithActive from '@components/icon-with-active';
import Skeleton from '@components/skeleton';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';

import { SubscriptionsStatus } from 'components/SubscriptionsStatus';
import { staticTabsId, tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';

import { Tab } from '../Tab';
import { TabsProps } from '../Tabs';
import tabsCss from '../Tabs.module.css';
import { NewFavoritesTabInformer } from './NewFavoritesTabInformer';

import css from './Static.module.css';

const SpecialTab = dynamic(() => import('../Tab/SpecialTab').then(mode => mode.SpecialTab), {
  ssr: false,
  loading: () => <Skeleton height={56} width={214} />,
});

interface StatisProps extends TabsProps {
  isSticky: boolean;
}

const Static = (props: StatisProps) => {
  const { tabsData, activeTab, onChange, isPinned, onPin, onDisabledClick } = props;

  const {
    followedLoads: { favorites },
    siteFeatures,
  } = useStore();

  const mainTabs = tabsData.slice(0, -1);
  const lastTab = tabsData[tabsData.length - 1];

  return (
    <div>
      {siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.newFavoritesTab] && <NewFavoritesTabInformer />}

      <div className={css.container} id={staticTabsId}>
        <div className={css.mainTabs}>
          {isPinned && (
            <IconWithActive
              activeIcon={<Icon color="var(--glz-color-neutral-tone-0)" height={14} name="ic_pin" width={14} />}
              className={cn(tabsCss.pinButton, tabsCss.pinButton_static)}
              defaultIcon={<Icon color="var(--glz-color-neutral-tone-4)" height={14} name="ic_pin" width={14} />}
              onClick={onPin}
            />
          )}

          <div className={css.mainTabs}>
            {mainTabs.map(tab => (
              <Tab
                key={tab.id}
                isActive={tab.id === activeTab}
                tab={tab}
                onChange={onChange}
                onDisabledClick={onDisabledClick}
              >
                {tab.label}

                {tab.id === tabIds.favorites && Boolean(favorites.count) && (
                  <span className={css.counter}>{favorites.count}</span>
                )}

                {tab.id === tabIds.savedFilters && <SubscriptionsStatus />}
              </Tab>
            ))}
          </div>
        </div>

        <SpecialTab
          isSpecial
          isActive={lastTab.id === activeTab}
          tab={lastTab}
          onChange={props.onChange}
          onDisabledClick={onDisabledClick}
        />
      </div>
    </div>
  );
};

const connected = observer(Static);

export { connected as Static };
