import Select from '@components/select';
import { observer } from 'mobx-react-lite';

import { OptionsList } from 'components/OptionsList';
import { RestrictedInput } from 'components/RestrictedInput';
import { inputRestriction } from 'constants/inputRestrictions';
import { useStore } from 'hooks/useStore';

import css from './Content.module.css';

const Content = () => {
  const {
    app: { i18n },
    options,
    filter: { extraParams, rate },
  } = useStore();

  return (
    <>
      <OptionsList
        options={options.getPaymentTypes()}
        selectedValues={extraParams.params}
        valueProperty="id"
        onChange={extraParams.setParam}
      />

      <div className={css.rateInputContainer}>
        <RestrictedInput
          clearZeroOnBlur
          classNames={{ wrapper: css.rateInputWrapper }}
          customRestrict={['excessLeadingZeros', 'leadingSymbols']}
          inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
          maxDecimalPlaces={3}
          maxLength={7}
          placeholder={i18n.common.from}
          value={rate.value.value}
          onValueChange={rate.value.setValueFromInput}
        />

        <Select
          className={css.rateTypeSelect}
          classNames={{ select: css.rateTypesInnerSelect }}
          options={rate.currencyTypeId.options}
          value={rate.currencyTypeId.option}
          onChange={rate.currencyTypeId.setOption}
        />
      </div>
    </>
  );
};

const connected = observer(Content);

export { connected as Content };
