import Icon from '@components/icon';
import { observer } from 'mobx-react-lite';

import { loadsSearchFAQ } from 'constants/pages';
import { tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { TabsContent } from 'layout/TabsContent';
import { QueryType } from 'store/QueryStore/QueryStore';
import { TSubscribedFilter } from 'store/Subscriptions/SubscribedFilter';
import { SavedFilter, ViewFilterTypes } from 'store/TabsDataStore/TabsDataStore';
import { interpolate } from 'utils/string/format';

import { FilterByTypeDropdown } from './FilterByTypeDropdown';

import css from './SavedFilters.module.css';

const SavedFilters = (props: { className?: string }) => {
  const {
    app: { i18n, env, fillFilterFromQuery, loading },
    query,
    tabsData,
    ui,
    savedFilters,
  } = useStore();

  const handleSubscriptionClick = (filter: SavedFilter) => {
    savedFilters.openFilterPopup(filter, {
      enableSubscription: false,
    });
  };

  const handleFilterClick = (filter: SavedFilter) => {
    ui.setActiveTab(tabIds.search);
    tabsData.selectFilter(filter);
    query.setParsedQueryData(filter.filter);
    query.setType(QueryType.byFilter);
    fillFilterFromQuery({ skipQueryParsing: true });
    // todo сравнить хэш заполненного фильтра и сохранённого. Если они не совпадают — написал ошибку в лог
  };

  const handleFilterDelete = async (filter: SavedFilter) => {
    await tabsData.deleteSavedFilter(filter.id);
  };

  const handleFilterUnsubscribe = async (filter: TSubscribedFilter) => {
    await filter.instance?.unsubscribe();
  };

  return (
    <TabsContent<TSubscribedFilter>
      activeItem={tabsData.selectedFilter}
      className={props.className}
      header={
        <TabsContent.Header>
          <FilterByTypeDropdown />
          <a
            className="glz-link glz-is-gray"
            href={interpolate(loadsSearchFAQ, { env })}
            rel="noreferrer"
            target="_blank"
          >
            {i18n.app.moreOnFilters.firstPart}{' '}
            <span className={css.linkTextSecondPart}>{i18n.app.moreOnFilters.secondPart}</span>
          </a>
        </TabsContent.Header>
      }
      isLoading={loading.deleteSavedFilter || loading.tabsData}
      items={tabsData.filtersView || []}
      itemsCounter="filter"
      noContentElement={
        <TabsContent.NoContent
          desc={
            <span>
              {i18n.savedFilters.noContent.desc[tabsData.viewFilterType]}{' '}
              {i18n.savedFilters.noContent.desc.common.firstPart}{' '}
              <button className="glz-link glz-is-medium glz-is-primary" onClick={() => ui.setActiveTab(tabIds.search)}>
                {i18n.savedFilters.noContent.desc.common.action}
              </button>
              {tabsData.viewFilterType === ViewFilterTypes.all && <br />}
              {i18n.savedFilters.noContent.desc.common.secondPart}
            </span>
          }
          icon={<Icon color="var(--glz-color-neutral-tone-3)" height={32} name="ic_lens" width={32} />}
          title={i18n.savedFilters.noContent.title[tabsData.viewFilterType]}
        />
      }
      onItemClick={handleFilterClick}
      onItemDelete={handleFilterDelete}
      onItemSubscribe={handleSubscriptionClick}
      onItemUsubscribe={handleFilterUnsubscribe}
    />
  );
};

const connected = observer(SavedFilters);

export { connected as SavedFilters };
