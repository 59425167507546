import Icon from '@components/icon';
import pluralize from 'ati-utils/pluralize';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { followedLoadsTabIds } from 'store/UIStore/UIStore';

import css from './GroupOperations.module.css';

const selectedLoadsSources = {
  [followedLoadsTabIds.onlyFavorites]: 'favorites',
  [followedLoadsTabIds.onlyWithOffers]: 'loadsWithOffers',
} as const;

const GroupOperations = () => {
  const store = useStore();
  const {
    app: { i18n, hideSelectedLoads, loading },
    view,
    profile,
    ui,
    followedLoads,
  } = store;

  if (ui.activeTab !== tabIds.favorites && ui.activeTab !== tabIds.search) return null;

  const followedLoadsSource = selectedLoadsSources[ui.followedLoadsTab.data];
  const activeTabStore = ui.activeTab === tabIds.favorites ? followedLoads[followedLoadsSource] : store.app;
  const selectedLoads = activeTabStore.selectedLoads;

  const handlePrintLoads = () => {
    const isLoggedIn = profile.data?.isUser;

    if (isLoggedIn) {
      ui.setPrintMode(true);
    } else {
      ui.openLoginPopup();
    }
  };

  const handleHideLoads = () => {
    if (!profile.data?.isUser) {
      ui.openLoginPopup();
      return;
    }

    hideSelectedLoads();
  };

  const shouldShowHideButton = ui.activeTab === tabIds.search;
  const shouldShowRemoveFromFavorites =
    ui.activeTab === tabIds.favorites && followedLoadsTabIds.onlyFavorites === ui.followedLoadsTab.data;

  return (
    <div className={cn(css.groupOperations, { hidden: !selectedLoads.count })}>
      <div className={css.infoTextContainer}>
        <p className={css.infoText}>
          {pluralize({ value: selectedLoads.count, ...i18n.app.declensions.selected })}{' '}
          {view.pluralizedCount(selectedLoads.count, 'loads')}
        </p>
        <button className={css.iconButton} onClick={selectedLoads.unselectAll}>
          <Icon color="var(--glz-color-neutral-tone-4)" height="14" name="ic_none" width="14" />
        </button>
      </div>

      <div className={css.operations}>
        <button
          className={cn(css.primaryOperation, 'glz-button glz-is-primary glz-is-outlined glz-is-small')}
          onClick={handlePrintLoads}
        >
          {i18n.app.print}
        </button>

        {shouldShowHideButton && (
          <button
            className={cn(css.secondaryOperation, 'glz-button glz-is-primary glz-is-small', {
              ['glz-is-loading']: loading.hideLoads,
            })}
            onClick={handleHideLoads}
          >
            {i18n.app.hide}
          </button>
        )}

        {shouldShowRemoveFromFavorites && (
          <button
            className={cn(css.secondaryOperation, 'glz-button glz-is-primary glz-is-small', {
              ['glz-is-loading']: followedLoads.favorites.loading.removeSelectedFavorites,
            })}
            onClick={followedLoads.favorites.removeSelectedFavorites}
          >
            {i18n.app.delete}
          </button>
        )}
      </div>
    </div>
  );
};

const connected = observer(GroupOperations);

export { connected as GroupOperations };
