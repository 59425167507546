import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { loadsSearchFAQ } from 'constants/pages';
import { tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { TabsContent } from 'layout/TabsContent';
import { QueryType } from 'store/QueryStore/QueryStore';
import { SavedFilter } from 'store/TabsDataStore/TabsDataStore';
import { interpolate } from 'utils/string/format';

import { HistroryIcon } from './HistroryIcon';

import css from './History.module.css';

const History = (props: { className?: string }) => {
  const {
    app: { i18n, env, fillFilterFromQuery, loading },
    query,
    tabsData,
    ui,
    savedFilters,
  } = useStore();

  const handleFilterClick = (filter: SavedFilter) => {
    ui.setActiveTab(tabIds.search);
    tabsData.selectFilter(filter);
    query.setParsedQueryData(filter.filter);
    query.setType(QueryType.byFilter);
    fillFilterFromQuery({ skipQueryParsing: true });
  };

  const handleFilterDelete = (filter: SavedFilter) => {
    tabsData.deleteLastSearch(filter.id);
  };

  const handleSubscriptionClick = (filter: SavedFilter) => {
    savedFilters.openFilterPopup(filter, { enableSubscription: true });
  };

  return (
    <TabsContent
      activeItem={tabsData.selectedFilter}
      className={props.className}
      header={
        <TabsContent.Header>
          <a
            className={cn('glz-link', 'glz-is-gray', css.link)}
            href={interpolate(loadsSearchFAQ, { env })}
            rel="noreferrer"
            target="_blank"
          >
            {i18n.app.moreOnFilters.firstPart}{' '}
            <span className={css.linkTextSecondPart}>{i18n.app.moreOnFilters.secondPart}</span>
          </a>
        </TabsContent.Header>
      }
      isLoading={loading.deleteLastSearchRequest || loading.tabsData}
      items={tabsData.data?.lastSearches || []}
      noContentElement={
        <TabsContent.NoContent
          desc={i18n.history.noContent.desc}
          icon={<HistroryIcon />}
          title={i18n.history.noContent.title}
        />
      }
      onItemClick={handleFilterClick}
      onItemDelete={handleFilterDelete}
      onItemSubscribe={handleSubscriptionClick}
    />
  );
};

const connected = observer(History);

export { connected as History };
