import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import css from './Trial.module.css';

interface ITrial {
  mode: 'full' | 'short';
}

const Trial = ({ mode }: ITrial) => {
  const {
    app: { i18n },
    savedFilters: { popup },
    profile,
    view,
  } = useStore();

  const shouldHideTrial =
    !popup?.notificationsRate ||
    !popup.hasSubscriptionTrialAvailable ||
    !popup.smsParams ||
    profile.hasUnlimitedNotificationsLicense ||
    popup.hasFreeSubscription;

  if (shouldHideTrial) return null;

  const trialExpirationText = popup.validationResult?.trialVersionEndDateTime
    ? interpolate(i18n.subscriptions.trial.exactDate, { date: popup.validationResult?.trialVersionEndDateTime })
    : interpolate(i18n.subscriptions.trial.freeDays, {
        days: popup.validationResult?.cargoSubscriptionsTrialDaysDuration?.toString() || '',
      });

  return (
    <div className={css.trial}>
      {!popup.isTrialSubscriptionActivated && <span className={css.special}>{i18n.subscriptions.trial.special}</span>}

      <p className={cn('glz-p glz-is-size-3', css.expiration)}>
        <strong>{trialExpirationText}</strong>{' '}
        <span>
          {interpolate(i18n.subscriptions.trial.priceAfter, { atis: view.atisCount(Number(popup?.notificationsRate)) })}
        </span>
      </p>

      {mode === 'full' && (
        <>
          <p className={cn('glz-p glz-is-size-3', css.text)}>{i18n.subscriptions.trial.saveTime}</p>
          <p className={cn('glz-p glz-is-size-3', css.text)}>{i18n.subscriptions.trial.freePersonalBoards}</p>
        </>
      )}
    </div>
  );
};

const connected = observer(Trial);

export { connected as Trial };
